<template>
    <div class="hello">
        <h1>LoginPage</h1>
        <form>
            <input v-model="login" type="text" placeholder="Login"><br>
            <input v-model="password" type="password" placeholder="Pass"><br>
            <input @click.prevent="tryLogin" type="submit">
        </form>
    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

export default {
    name: "LoginPage",
    data() {
        return {
            login: "",
            password: "",
        }
    },
    methods: {
        ...mapActions(['signIn']),
        ...mapActions(['tokenSignIn']),
        tryLogin() {
            this.signIn(this.login, this.password).then(() => {
                alert("Success")
            }).catch(() => {
                alert("Данные не правильные")
            })
        },
        tryTokenLogin() {
            this.tokenSignIn().then(() => {
                alert("Success")
            }).catch(() => {
                alert("Данные не правильные")
            })
        },
    },
    created() {
        this.tryTokenLogin()
    },
    computed: {
        ...mapGetters(['getAuth'])
    },
}
</script>

<style scoped></style>