<template>
<div class="container">
  <OneHoloCard class="animation" v-bind:class="Card3 && 'animate'" data-image="https://i.postimg.cc/4dZ1rwr9/temp-Image-OVbxl-G.avif">
    <template v-slot:header><h1>Web Dev</h1></template>
    <template v-slot:content><p>More then 3 years of development commercial websites</p></template>
  </OneHoloCard>
  <OneHoloCard class="animation" v-bind:class="Card3 && 'animate'" data-image="https://i.postimg.cc/nV31P4TX/temp-Image-HX0-Fv2.avif">
    <template v-slot:header><h1>Design</h1></template>
    <template v-slot:content><p>100+ Ready design templates. Web-sites, creatives, ads</p></template>
  </OneHoloCard>
  <OneHoloCard class="animation" v-bind:class="Card3 && 'animate'" data-image="https://i.postimg.cc/dVym3zFC/temp-Image-H8-Jm-ZW.avif">
    <template v-slot:header><h1>3D Graphics</h1></template>
    <template v-slot:content><p>More then 120 projects created with 3D. Interiors, models, photorealistic renders</p></template>
  </OneHoloCard>
  <OneHoloCard class="animation" v-bind:class="Card4 && 'animate'" data-image="https://i.postimg.cc/pT2D0rFQ/temp-Image-W1i-LQa.avif">
    <template v-slot:header><h1>Web Security</h1></template>
    <template v-slot:content><p>Audited over 300 web sites and fixed vulnerabilities</p></template>
  </OneHoloCard>
</div>
</template>

<script>
import OneHoloCard from './OneHoloCard.vue'
export default {
  name: 'HoloCards',
  components: {
    OneHoloCard,
  },
  data() {
    return {
      Card1: false,
      Card2: false,
      Card3: false,
      Card4: false,
    }
  },
  mounted () {
    setTimeout(() => {
      this.Card1 = true
    }, 500)
    setTimeout(() => {
      this.Card2 = true
    }, 1000)
    setTimeout(() => {
      this.Card3 = true
    }, 1500)
    setTimeout(() => {
      this.Card4 = true
    }, 2000)
  }
}
</script>
  
<style scoped>
h1, p {
  color: rgb(255, 255, 255);
  text-shadow: 0px 0px  5px #000;
}


.animation {
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.5s;
}

.animation.animate {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.5s;
}

.CardHidden {
    opacity: 0;
    transform: translateY(-50px);
    transition: all 0.5s;
}

.CardHidden.CardShown {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.5s;
}
</style>
  