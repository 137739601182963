<template>
  <div class="hello">
    <h1 class="will_be_hidden main_msg">{{ msg }}</h1>
    <div class="will_be_hidden container py-5">
      <div class="output" id="output">
        <h1 class="cursor"></h1>
        <p></p>
      </div>
    </div>
    <div class="hover_circle"></div>
    <div class="will_be_hidden change_page_button">
      <a @click="changePageButtonClick()" @mouseenter="changePageButtonHover()" @mouseleave="changePageButtonLeave()" href="#">Start exploring</a>
    </div>
  </div>
</template>

<script>
import "core-js/es/array";
import $ from 'jquery';

export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  data() {
    return {
      textArray: [ 
        "Landing Pages",
        "E-Shops",
        "Business websites" 
      ],
      i: 0,
      a: 0,
      isBackspacing: false,
      isParagraph: false,
      speedForward: 100,
      speedWait: 1000,
      speedBetweenLines: 1000,
      speedBackspace: 25,
    };
  },
  // watch: {
  //   i(newVal, oldVal) {
  //     console.log(newVal)
  //     console.log(oldVal)
  //   }
  // },
  mounted() {
    this.typeWriter();
  },
  methods: {
    typeWriter() {
      const element = $("#output");
      const aString = this.textArray[this.a];
      const eHeader = element.children("h1");
      const eParagraph = element.children("p");

      if (!this.isBackspacing) {
        if (this.i < aString.length) {
          if (aString.charAt(this.i) === "|") {
            this.isParagraph = true;
            eHeader.removeClass("cursor");
            eParagraph.addClass("cursor");
            this.i++;
            setTimeout(this.typeWriter, this.speedBetweenLines);
          } else {
            if (!this.isParagraph) {
              eHeader.text(eHeader.text() + aString.charAt(this.i));
            } else {
              eParagraph.text(eParagraph.text() + aString.charAt(this.i));
            }
            this.i++;
            setTimeout(this.typeWriter, this.speedForward);
          }
        } else if (this.i === aString.length) {
          this.isBackspacing = true;
          setTimeout(this.typeWriter, this.speedWait);
        }
      } else {
        if (eHeader.text().length > 0 || eParagraph.text().length > 0) {
          if (eParagraph.text().length > 0) {
            eParagraph.text(eParagraph.text().substring(0, eParagraph.text().length - 1));
          } else if (eHeader.text().length > 0) {
            eParagraph.removeClass("cursor");
            eHeader.addClass("cursor");
            eHeader.text(eHeader.text().substring(0, eHeader.text().length - 1));
          }
          setTimeout(this.typeWriter, this.speedBackspace);
        } else {
          this.isBackspacing = false;
          this.i = 0;
          this.isParagraph = false;
          this.a = (this.a + 1) % this.textArray.length;
          setTimeout(this.typeWriter, 50);
        }
      }
    },
    changePageButtonHover() {
      document.querySelector(".hover_circle").classList.add("hovered")
    },
    changePageButtonLeave() {
      document.querySelector(".hover_circle").classList.remove("hovered")
    },
    changePageButtonClick() {
      document.querySelector(".hover_circle").classList.add("clicked")
      setTimeout(() => {
        const hideElements = document.querySelectorAll(".will_be_hidden");
        hideElements.forEach(element => {
          element.classList.add("hidden");
        });
      }, 200);
      setTimeout(() => {
        this.$router.push({ path: '/portfolio' });
      }, 1300);
    }
  }
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.hover_circle {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 0px;
  height: 0px;
  z-index: 2;
  border-radius: 1000px;
  pointer-events: none;
  backdrop-filter: invert(1);
  transition: all 0.25s;
}

.will_be_hidden.hidden {
  opacity: 0;
  transition: all 1s;
}


.hover_circle.hovered {
  width: 50px;
  height: 50px;
  transition: all 0.25s;
}

.hover_circle.clicked {
  width: 100vw;
  height: 100vh;
  border-radius: 0px;
  transition: all 0.5s;
}

.change_page_button {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}

.change_page_button a {
  text-decoration: none;
  color: #000;
  padding: 20px;
  font-weight: bold;
}

.main_msg {
  color: #000;
}

.output {
  text-align: center;
  font-family: 'Source Code Pro', monospace;
  color: #000;
}

.output h1 {
  font-size: 30px;
}

/* Cursor Styling */

.cursor::after {
  content: '';
  display: inline-block;
  margin-left: 3px;
  background-color: #000;
  animation-name: blink;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
}

h1.cursor::after {
  height: 24px;
  width: 13px;
}

p.cursor::after {
  height: 13px;
  width: 6px;
}

@keyframes blink {
  0%, 49% { opacity: 1; }
  50%, 100% { opacity: 0; }
}
</style>

