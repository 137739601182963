import { createStore } from 'vuex'

const store = createStore({
  state: {
    auth: false,
    token: localStorage.getItem('authTokenData') || '',
  },
  mutations: {
    setAuth(state, auth) {
        state.auth = auth
    },
    setToken(state, token) {
        state.token = token
        localStorage.setItem('authTokenData', token)
    }
  },
  actions: {
    async signIn(store, login, password) {
        setTimeout(() => {
            console.log(login, password)
            store.commit('setAuth', true)
            store.commit('setToken', "token123123")
        }, 500)
    },
    async tokenSignIn(store) {
        setTimeout(() => {
            console.log(store.state.token)
            if (store.state.token === '') {
                store.commit('setAuth', false)
            } else {
                store.commit('setAuth', true)
            }
            
        }, 500)
    } 
  }, 
  getters: {
    getAuth: (state) => state.auth   
  },
  modules: {

  },
});

export default store
