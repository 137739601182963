import { createRouter, createWebHistory } from 'vue-router';
import HelloWorld from "@/components/HelloWorld.vue";
import PortfolioPage from '@/components/PorfolioPage.vue';
import AdminPage from '@/components/AdminPage.vue';

const routes = [
    {
      path: "/",
      name: "Home",
      component: HelloWorld
    },
    {
      path: "/portfolio",
      name: "Portfolio",
      component: PortfolioPage
    },
    {
      path: "/admin",
      name: "Admin",
      component: AdminPage
    }
  ];

const router = createRouter({
history: createWebHistory(),
routes,
})

export default router
