<template>
    <div class="hello">
        <h1>ControlPanel</h1>
    </div>
</template>

<script>
export default {
    name: "ControlPanel"
}
</script>

<style scoped>

</style>