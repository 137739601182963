<template>
    <div class="content__container">
        <div class="timeline-container" ref="timeline">
            <div class="timeline">
                <div v-for="(event, index) in events" :key="index" class="timeline-item"
                    :style="{ top: `${index * 120}px` }">
                    <div class="dot"></div>
                    <div class="date">{{ event.date }}</div>
                </div>
                <div class="timeline-fill" :style="{ height: `${scrollPercentage}%` }"></div>
            </div>
        </div>
        <canvas id="confetti-container"></canvas>
    </div>
</template>

<script>
import confetti from "canvas-confetti";

export default {
    data() {
        return {
            events: [
                { date: "07.04.2021" },
                { date: "03.06.2022" },
                { date: "03.06.2024" },
                { date: "26.11.2024" },
            ],
            scrollPercentage: 0,
            isFullyVisible: false,
            isScrollBlocked: false,
            confettiTriggered: false,
        };
    },
    mounted() {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.intersectionRatio === 1 && !this.confettiTriggered) {
                    this.isFullyVisible = true;
                    this.isScrollBlocked = true;
                }
            },
            {
                threshold: 1,
            }
        );

        observer.observe(this.$refs.timeline);

        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll(event) {
            if (!this.isFullyVisible || this.confettiTriggered) return;

            if (this.isScrollBlocked) {
                event.preventDefault();
            }

            const totalScrollHeight = 300;

            const scrollDelta = window.scrollY - this.lastScrollY || 0;
            this.lastScrollY = window.scrollY;

            const fillIncrease = (scrollDelta / totalScrollHeight) * 100;
            this.scrollPercentage = Math.min(this.scrollPercentage + fillIncrease, 100);

            if (this.scrollPercentage >= 0 && this.scrollPercentage < 33) {
                this.$emit("update-award-text", "Developing my first Wordpress website for a school event");
            }

            if (this.scrollPercentage >= 33 && this.scrollPercentage < 66) {
                this.$emit("update-award-text", "I am enrolled in the IT Step computer academy, where I am learning basic languages to create websites");
            }

            if (this.scrollPercentage >= 66 && this.scrollPercentage < 99) {
                this.$emit("update-award-text", "I'm getting my degree, and continue to work on freelance platforms in the field of web development");
            }

            if (this.scrollPercentage === 100 && !this.confettiTriggered) {
                this.$emit("update-award-text", "I have successfully completed more than 50 web development assignments and am applying to the University of Andorra");
                this.triggerConfetti();
                this.confettiTriggered = true;
                setTimeout(() => {
                    document.querySelector("#confetti-container").remove()
                }, 5000)
            }
        },
        triggerConfetti() {
            const confettiContainer = document.getElementById('confetti-container');

            const MyConfetti = confetti.create(confettiContainer, {
                resize: true, 
                useWorker: true
            });

            MyConfetti({
                particleCount: 200,
                spread: 70,
                origin: { y: 0 },
                angle: 270,
                startVelocity: 20,
                gravity: 0.2,
                disableForReducedMotion: true,
            });
        },
    },
};
</script>

<style scoped>
.content__container {
    display: block;
}

#confetti-container {
    height: 500px;
    position: absolute;
    left: 0;
    transform: translateX(-21%);
    z-index: 1;
}

@media screen and (max-width: 700px) {
    #confetti-container {
        max-width: 50vw;
        width: 50vw;
    }
}

.filler {
    height: 100vh;
}

.timeline-container {
    position: relative;
    height: 370px;
    margin: 0 auto;
    width: 4px;
    background-color: #000000;
}

.timeline {
    position: relative;
    width: 4px;
    height: 100%;
}

.timeline-fill {
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    border-radius: 100px;
    background-color: #ffffff;
    transition: height 0.2s;
}

.timeline-item {
    position: absolute;
    left: -3px;
    transform: translateY(-5px);
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: start;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    border-radius: 50%;
    margin-right: 10px;
}

.date {
    font-size: 14px;
}
</style>