<template>
    <div class="hello">
        <div class="open_close" @click="openHideBurger()"></div>
        <MainMenu class="mainMenu" @scroll-to="scrollTo"/>
        <MainMobileMenu class="mainMobileMenu" @scroll-to="scrollToSetcionMobile"/>
        <div class="main_card">
            <img class="animation avatar" src="../assets/avatar.png" alt="avatar">
            <div class="main_card_text">
                <h1 class="animation">Taras Stakhovsky</h1>
                <h2 class="animation">Front-end Web Developer</h2>
            </div>
        </div>
        <h2 style="font-size: xx-large;" class="animation block_" ref="Main">Main Skills</h2>
        <HoloCards />
        <div class="summary block_" style="padding-left: 10vw; padding-right: 10vw;">
            <h2 style="font-size: xx-large;" class="animation" ref="Summary">Summary</h2>
            <h3 class="animation"><b>Experience:</b> 3 years</h3>
            <h3 class="animation"><b>Technology Stack:</b> Vue.js, JavaScript, TypeScript, HTML, CSS, PHP</h3>
            <p class="text-align-start animation">I am a motivated and skilled Junior+ Front-end Developer with 3 years of hands-on experience in web development. My journey began at the Ukrainian academy <b>IT Step</b>, where I graduated with a specialization in Front-end Development. Additionally, I completed studies at the junior branch of the same academy, solidifying my foundational skills.</p>
            <p class="text-align-start animation">Throughout my career, I have actively worked on freelance platforms such as FreelanceHunt, Upwork, and Fiverr, collaborating with clients worldwide on commercial projects. My portfolio includes:</p>
            <ul>
                <li class="text-align-start animation">Developing e-commerce platforms with robust functionality.</li>
                <li class="text-align-start animation">Building interactive web services tailored to user needs.</li>
                <li class="text-align-start animation">Crafting visually appealing, conversion-driven landing pages for diverse industries.</li>
            </ul>
            <p class="text-align-start animation">I pride myself on my ability to transform ideas into functional, user-friendly interfaces while staying up-to-date with modern web development practices. My adaptability, problem-solving skills, and strong work ethic allow me to deliver high-quality solutions that exceed client expectations.</p>
            <p class="text-align-start animation">Beyond technical expertise, I thrive in team-oriented environments, effectively communicating and coordinating with colleagues to bring projects to fruition. My passion for continuous learning and improvement drives me to refine my skills and contribute meaningfully to the digital landscape.</p>
            <p class="text-align-start animation">I'm eager to further my education at the University of Andorra and leverage my knowledge to achieve new milestones in the field of technology and innovation.</p>
        </div>
        <div class="awards block_">
            <h2 style="font-size: xx-large;" class="animation" ref="Timeline">Timeline</h2>
            <div class="awards_block">
                <AwardsLine class="awardsLine" @update-award-text="updateAwardTextContent"/>
                <div class="awards_text">
                    <h3 class="award_text animation" > {{ awardsTextContent }}</h3>
                </div>
            </div>
        </div>
        <div class="contacts block_">
            <h2 style="font-size: xx-large;" class="animation" ref="Contacts">Contact Me</h2>
            <div class="flex_contacts">
                <div class="contact animation">
                    <h4 class="animation">Telegram</h4>
                    <h5 class="animation "><a class="shareButton" style="text-decoration: none;" href="https://t.me/user31_12">Text me</a></h5>
                </div>

                <div class="contact animation">
                    <h4 class="animation">Phone</h4>
                    <h5 class="animation"><a style="color: #FFF;" href="tel:+380685970919">+380 (68) 597 0919</a></h5>
                </div>

                <div class="contact">
                    <h4 class="animation">Email</h4>
                    <h5 class="animation"><a style="color: #FFF;" href="mailto:sugargoose2424@gmail.com">sugargoose2424@gmail.com</a></h5>
                </div>

                <div class="contact">
                    <h4 class="animation">Share</h4>
                    <h5 class="animation"><a class="shareButton" @click="shareButton">Share this site</a></h5>
                </div>
            </div>
        </div>

        <div class="copyright block_" ref="Copyright">
            <h3 class="animation">All content is protected by copyright. Any copying, or mentioning without the owner's permission is prohibited.</h3>
            <h3 class="animation">Copyright © {{ CurrentYear }}</h3>
        </div>
    </div>
</template>

<script>
import HoloCards from './HoloCards.vue'
import { waitforme } from '@/libraries/lib'
import MainMenu from '@/components/PortfolioPageMenu.vue'
import AwardsLine from '@/components/AwardsLine.vue';
import Date from 'core-js/es/date';
import MainMobileMenu from '@/components/MainMobileMenu.vue';
export default {
    name: 'HelloWorld',
    props: {
        msg: String,
    },
    components: {
        HoloCards,
        MainMenu,
        AwardsLine,
        MainMobileMenu
    },
    mounted() {
        document.body.style.backgroundColor = "#000";
        document.body.style.overflowX = "hidden";
        this.animationFunc()
        this.openHideBurger()
    },
    data() {
        return {
            awardsTextContent: "Developing my first Wordpress website for a school event",
            width: 0,
            height: 0,
            mouseX: 0,
            mouseY: 0,
            mouseLeaveDelay: null,
            cardData: [
                "https://images.unsplash.com/photo-1479660656269-197ebb83b540?dpr=2&auto=compress,format&fit=crop&w=1199&h=798&q=80&cs=tinysrgb&crop=",
                "https://images.unsplash.com/photo-1479659929431-4342107adfc1?dpr=2&auto=compress,format&fit=crop&w=1199&h=799&q=80&cs=tinysrgb&crop=",
                "https://images.unsplash.com/photo-1479644025832-60dabb8be2a1?dpr=2&auto=compress,format&fit=crop&w=1199&h=799&q=80&cs=tinysrgb&crop=",
                "https://images.unsplash.com/photo-1479621051492-5a6f9bd9e51a?dpr=2&auto=compress,format&fit=crop&w=1199&h=811&q=80&cs=tinysrgb&crop="
            ],
            CurrentYear: new Date().getFullYear(),
            burgerState: 1,
        };
    },
    computed: {
        mousePX() {
            return this.mouseX / this.width;
        },
        mousePY() {
            return this.mouseY / this.height;
        },
        cardStyle() {
            const rX = this.mousePX * 30;
            const rY = this.mousePY * -30;
            return {
                transform: `rotateY(${rX}deg) rotateX(${rY}deg)`
            };
        },
        cardBgTransform() {
            const tX = this.mousePX * -40;
            const tY = this.mousePY * -40;
            return {
                transform: `translateX(${tX}px) translateY(${tY}px)`
            }
        },
        cardBgImage() {
            return {
                backgroundImage: `url(${this.cardData[0]})`
            }
        },
    },
    methods: {
        handleMouseMove(e) {
            this.mouseX = e.pageX - this.$refs.card.offsetLeft - this.width / 2;
            this.mouseY = e.pageY - this.$refs.card.offsetTop - this.height / 2;
        },
        handleMouseEnter() {
            clearTimeout(this.mouseLeaveDelay);
        },
        handleMouseLeave() {
            this.mouseLeaveDelay = setTimeout(() => {
                this.mouseX = 0;
                this.mouseY = 0;
            }, 1000);
        },
        animationFunc() {
            const elements = document.querySelectorAll(".animation");
            const observer = new IntersectionObserver(async (entries) => {
                for(const entry of entries) {
                    if (entry.isIntersecting) {
                        await waitforme(125)
                        entry.target.classList.add("animate");
                        observer.unobserve(entry.target);
                    }
                }
            });

            elements.forEach((element) => observer.observe(element));
        },
        updateAwardTextContent(newText) {
            this.awardsTextContent = newText;
        },
        async shareButton() {
            if (navigator.share) {
                try {
                    await navigator.share({
                        text: 'Taras Stakhovsky\nWeb Develooper\nOrder your website now - https://taras-stakhovsky.online',
                    });
                    console.log('Shared');
                } catch (error) {
                    console.error('Error while sharing:', error);
                }
            } else {
                alert('Sharing is not allowed in your browser');
            }
        },
        scrollTo(sectionId) {
            const section = this.$refs[sectionId];
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        },

        scrollToSetcionMobile(sectionId) {
            const section = this.$refs[sectionId];
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
                document.querySelector('.mainMobileMenu').classList.remove('show')
                this.hideBurger()
            }
        },
        openHideBurger() {
            console.log(this.burgerState)
            if (this.burgerState % 2 === 0) {
                this.openBurger()
            } else {
                this.hideBurger()
            }
        },
        openBurger() {
            this.burgerState ++
            document.querySelector('.mainMobileMenu').classList.add('show')
                document.querySelector(".open_close").innerHTML = `<svg fill="#FFF" height="32px" width="32px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 460.775 460.775" xml:space="preserve">
                <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
                c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
                c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
                c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
                l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
                c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"/>
            </svg>`
        },
        hideBurger() {
            this.burgerState ++
            document.querySelector('.mainMobileMenu').classList.remove('show')
                document.querySelector(".open_close").innerHTML = `<svg width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 18L20 18" stroke="#FFF" stroke-width="2" stroke-linecap="round"/>
                <path d="M4 12L20 12" stroke="#FFF" stroke-width="2" stroke-linecap="round"/>
                <path d="M4 6L20 6" stroke="#FFF" stroke-width="2" stroke-linecap="round"/>
            </svg>`
        }
    }
}
</script>

<style scoped>
.mainMobileMenu {
    opacity: 0;
    pointer-events: none;
    transition: all 0.25s;
}

.mainMobileMenu.show {
    opacity: 1;
    pointer-events: all;
    transition: all 0.25s;
}

.open_close {
    cursor: pointer;
    position: fixed;
    top:30px;
    right:30px;
    z-index: 100000;
}

@media screen and (max-width: 1137px) {
    .mainMenu {
        display: none;
        visibility: hidden;
    }
    
}

@media screen and (min-width: 1139px) {
    .open_close {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .award_text {
        font-size: medium;
    }
}

.awardsLine {
    z-index: 1;
}

.mainMenu {
    z-index: 1000;
}

.block_ {
    margin-top: 100px;
}

.copyright.block_ {
    margin-top: 200px;
}

.shareButton {
    cursor: pointer;
    background-color: #FFF;
    padding:10px;
    border-radius: 5px;
    border: 2px solid #FFF;
    color: #000;
    transition: all 0.25s;
}

.shareButton:hover {
    background-color: #000;
    color: #FFF;
    border-radius: 50px;
    transition: all 0.25s;
}

.awards {
    z-index: 100;
}

canvas {
    z-index:1;
}

.contacts, .flex_contacts {
    z-index: 4;
}

.flex_contacts {
    z-index: 4;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap:50px;
}

.award_text {
    width: 100%;
    padding:0;
    margin:0;
}

.awards_text {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    height: 370px;
    width:20%;
}

.awards_block {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.animation {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.5s ease-out;
}

.animation.animate {
    opacity: 1;
    transform: translateY(0);
}

.hello {
    color: #FFF;
}

.main_card {
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
}

.main_card_text h1,
.main_card_text h2 {
    width: 100%;
    text-align: left;
}

.main_container_80 {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.avatar {
    border-radius: 1000px;
    width: 10vw;
}

@media screen and (max-width: 700px) {
    .avatar {
        width: 30vw;
    }
}


.title {
    font-family: "Raleway";
    font-size: 24px;
    font-weight: 700;
    color: #5d4037;
    text-align: center;
}

.container {
    padding: 40px 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card-wrap {
    margin: 10px;
    transform: perspective(800px);
    transform-style: preserve-3d;
    cursor: pointer;
}

.card-wrap:hover .card-info {
    transform: translateY(0);
}

.card-wrap:hover .card-info p {
    opacity: 1;
}

.card-wrap:hover .card-info,
.card-wrap:hover .card-info p {
    transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.card-wrap:hover .card-info:after {
    transition: 5s cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 1;
    transform: translateY(0);
}

.card-wrap:hover .card-bg {
    transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1), opacity 5s cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 0.8;
}

.card-wrap:hover .card {
    transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 2s cubic-bezier(0.23, 1, 0.32, 1);
    box-shadow: rgba(255, 255, 255, 0.2) 0 0 40px 5px, rgba(255, 255, 255, 1) 0 0 0 1px, rgba(0, 0, 0, 0.66) 0 30px 60px 0, inset #333 0 0 0 5px, inset white 0 0 0 6px;
}

.card {
    position: relative;
    flex: 0 0 240px;
    width: 240px;
    height: 320px;
    background-color: #333;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.66) 0 30px 60px 0, inset #333 0 0 0 5px, inset rgba(255, 255, 255, 0.5) 0 0 0 6px;
    transition: 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.card-bg {
    opacity: 0.5;
    position: absolute;
    top: -20px;
    left: -20px;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.width100 {
    width: 80%;
}

.card-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    font-family: "Raleway";
    color: #FFF;
    transform: translateY(100%);
}

.card-info h1 {
    font-size: 24px;
    font-weight: 700;
}

.card-info p {
    opacity: 0;
    font-size: 13px;
    margin-top: 5px;
    font-weight: 300;
}

.text-align-start {
    text-align: left;
}
</style>