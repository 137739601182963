<template>
    <div class="hello">
        <LoginPage/>
        <ControlPanel v-if="showControl"/>
    </div>
</template>

<script>
import LoginPage from "@/components/adminPageComps/LoginPage.vue"
import ControlPanel from "@/components/adminPageComps/ControlPanel.vue"
export default {
    name: "AdminPage",
    components: {
        LoginPage,
        ControlPanel
    },
    data() {
        return {
            showControl: false,
            login: "Login",
            password: "Password",
            isLoginPage: true,
        }
    }
}
</script>

<style scoped>

</style>