<template>
    <div v-bind:class="MobileMenu" class="main-container">
        <div @click="scrollToSetcionMobile('Main')" v-bind:class="MenuItem && 'animate'" class="item-container animation"><a>Main Skills</a></div>
        <div @click="scrollToSetcionMobile('Summary')" v-bind:class="MenuItem && 'animate'" class="item-container animation"><a>Summary</a></div>
        <div @click="scrollToSetcionMobile('Timeline')" v-bind:class="MenuItem && 'animate'" class="item-container animation"><a>Timeline</a></div>
        <div @click="scrollToSetcionMobile('Contacts')" v-bind:class="MenuItem && 'animate'" class="item-container animation"><a>Contacts</a></div>
        <div @click="scrollToSetcionMobile('Copyright')" v-bind:class="MenuItem && 'animate'" class="item-container animation"><a>Copyright</a></div>
    </div>
</template>

<script>
export default {
    name: 'MainMobileMenu',
    data() {
        return {
            
        }
    },
    methods: {
        scrollToSetcionMobile(sectionId) {
            this.$emit('scroll-to', sectionId);
        },
    },
}
</script>

<style scoped>
.main-container {
    position: fixed;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    padding: 0px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    gap:15px;
    background-color: #000;
    z-index: 1000;
}

ul {
    list-style:none;
    width: 2vw;
    padding: 0px;
    margin: 0px;
}

.item-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.divider {
    width: 20px;
    height: 2px;
    background-color: aliceblue;
}

.item-container a {
    cursor: pointer;
    font-size: large;
    transition: all 0.25s;
}

.item-container a:hover {
    transform: translateX(10px);
    transition: all 0.25s;
    opacity: 0.7;
}

.animation {
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.5s;
}

.animation.animate {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.5s;
}

</style>