<template>
    <div class="main-container">
        <div @click="scrollToSetcion('Main')" v-bind:class="MenuItem && 'animate'" class="item-container animation"><div class="divider"></div><a>Main Skills</a></div>
        <div @click="scrollToSetcion('Summary')" v-bind:class="MenuItem && 'animate'" class="item-container animation"><div class="divider"></div><a>Summary</a></div>
        <div @click="scrollToSetcion('Timeline')" v-bind:class="MenuItem && 'animate'" class="item-container animation"><div class="divider"></div><a>Timeline</a></div>
        <div @click="scrollToSetcion('Contacts')" v-bind:class="MenuItem && 'animate'" class="item-container animation"><div class="divider"></div><a>Contacts</a></div>
        <div @click="scrollToSetcion('Copyright')" v-bind:class="MenuItem && 'animate'" class="item-container animation"><div class="divider"></div><a>Copyright</a></div>
    </div>
</template>

<script>
export default {
    name: 'MainMenu',
    data() {
        return {

        }
    },
    methods: {
        scrollToSetcion(sectionId) {
            this.$emit('scroll-to', sectionId);
        }
    }
}
</script>

<style scoped>
.main-container {
    position: fixed;
    top:0;
    left:0;
    width: 10vw;
    height: 100vh;
    padding: 0px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    padding-left: 20px;
    gap:15px;
}

ul {
    list-style:none;
    width: 2vw;
    padding: 0px;
    margin: 0px;
}

.item-container {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.divider {
    width: 20px;
    height: 2px;
    background-color: aliceblue;
}

.item-container a {
    cursor: pointer;
    font-size: large;
    transition: all 0.25s;
}

.item-container a:hover {
    transform: translateX(10px);
    transition: all 0.25s;
    opacity: 0.7;
}

.animation {
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.5s;
}

.animation.animate {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.5s;
}

</style>